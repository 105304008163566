import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Member } from '../model/member';

import { webSocket } from "rxjs/webSocket";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})

export class WebsocketService {
  private WSBaseUrl;
  private user: any;
  private _WS_URL: any;
  private wsocket: any;
  private unique_id: any;

  constructor(private userService: UserService,
    private route: ActivatedRoute
    ) {
      this.WSBaseUrl = environment.websocketURL;
    }

   connectPointsWebSocket(uid){
    // initialize websocket
    // if(environment.websocketURL == 'localhost:8000/ws/'){
      // var ws_scheme = "wss";  
    // }else{
      // var ws_scheme = "wss";
    // }

     this._WS_URL = `${this.WSBaseUrl}points/${uid}/`;
    //  console.log('Socket connected!');
     return webSocket(this._WS_URL);
   }

   closePointsWebSocket(){
    // close websocket
    // console.log('Socket closed!');
    if(this.wsocket!==undefined){
      return this.wsocket.complete();
    }else{
      return;
    }
   }

   connectReferralWebSocket(uid){
    // initialize websocket
    // if(environment.websocketURL == 'localhost:8000/ws/'){
    //   var ws_scheme = "wss";  
    // }else{
      // var ws_scheme = "wss";
    // }
    this._WS_URL = `${this.WSBaseUrl}referrals/${uid}/`;
    //  console.log('Socket connected!');
     return this.wsocket = webSocket(this._WS_URL);
   }

   closeReferralWebSocket(){
    // close websocket
    // console.log('Socket closed!');
    if(this.wsocket!==undefined){
      return this.wsocket.complete();
    }else{
      return;
    }
   }
}
