import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'app-resend-email',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.scss']
})
export class ResendEmailComponent implements OnInit {

  timeLeft = 10;
  timeLeftLabel;
  interval;
  disableButton = true;
  loadingResendEmail = false;

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    this.startTimer();
  }

  startTimer() {
    this.timeLeftLabel = "(" + this.timeLeft + ")";
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
        this.timeLeftLabel = "(" + this.timeLeft + ")";
      } else if(this.timeLeft <= 0) {
        this.timeLeft = null;
        this.timeLeftLabel = "";
        this.disableButton = false;
      }
    },1000)
  }

  resendEmail(){
    this.loadingResendEmail = true;
    this.authService.resendEmail().subscribe((resp:any)=>{
      this.loadingResendEmail = false;
      location.reload();
    }, (err)=>{
      this.loadingResendEmail = false;
      location.reload();
    })
  }

}
