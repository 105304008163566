import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../service/authentication.service";
import { Member } from "../model/member";
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationCancel } from "@angular/router";
import { UserService } from "../service/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import { MatDialog } from "@angular/material";
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"]
})
export class BaseComponent implements OnInit {
  currentUser: Member;
  user: any;
  country_code: any;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.country_code = environment.country_code;
  }

  ngOnInit() {
        // Refetch user data on update/save/post/
        this.userService.refreshData$.subscribe(() => {
          this.getUser();
          // this.showConvertDialog();
        });
        this.getUser();
  }

  ngAfterViewInit() {
    this.router.events
        .subscribe((event) => {
            if(event instanceof NavigationStart) {
                this.spinner.show("content-spinner");
            }
            else if (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel
                ) {
                this.spinner.hide("content-spinner");
            }
        });
}

  private getUser() {
    this.userService.getUser().subscribe((user: any) => {
      this.user = user;
      // console.log(this.user)
    });
  }
}
