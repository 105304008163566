import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "src/app/service/user.service";
import { CopayDialogComponent } from 'src/app/copay-dialog/copay-dialog.component';
import { Member } from "src/app/model/member";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";

export interface DialogData {
  points: Number;
  user: any;
  referralInvalid: any;
}

@Component({
  selector: "app-convert-points-form",
  templateUrl: "./convert-points-form.component.html",
  styleUrls: ["./convert-points-form.component.scss"]
})
export class ConvertPointsFormComponent implements OnInit {
  conversionForm: FormGroup;
  loading = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ConvertPointsFormComponent>,
    private route: ActivatedRoute,
    private dialog: MatDialog, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.conversionForm = this.fb.group({
      points: ["", Validators.required]
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  
  openCoPayDialog(){
    let pointsToConvert = this.conversionForm.get("points").value;
    if(pointsToConvert !== ''){
      const coPaydialogRef = this.dialog.open(CopayDialogComponent, {
        id: 'coPayDialog'
      });
      
      // if Payment is confirmed process the payment if the points are valid
      coPaydialogRef.afterClosed().subscribe(result => {
        if(coPaydialogRef.componentInstance.paymentConfirmed){
          this.onConvertPoints();
        }
      });
    }
  }

  onConvertPoints() {    
    let pointsToConvert = this.conversionForm.get("points").value;
    if(pointsToConvert !== ''){
      this.loading = true;
      this.userService
      .convertPoints(pointsToConvert)
      .subscribe(
        res => {
          if(res['status']=='true'){
            this.toastr.success(`${res['message']}`, "", {
              positionClass: "toast-top-center",
            });
            this.dialogRef.close();
            this.router.navigate(['/']);
            this.loading = false;
          }else{
            // set error message from server side validation
            const formControl = this.conversionForm.get("points");
            if (formControl) {
              this.loading = false;
              formControl.setErrors({
                serverError: res['message']
              });
            }
          }
        },
        err => {
          this.loading = false; 
          console.log(err);
          this.dialogRef.close();
          this.toastr.error(`Unexpected error. Please try again later.`, "", {
            positionClass: "toast-top-center",
          });
        }
      );
    }
  }

  // private getUser() {
  //   this.userService.getUser().subscribe((user: Member) => {
  //     this.data.user = user;
  //   });
  // }

  ngOnInit() {
    // Refetch user data on update/save/post/
    // this.userService.refreshData$.subscribe(() => {
    //   this.getUser();
    // });
    // this.getUser();

  }
}
