import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-participating-stores',
  templateUrl: './participating-stores.component.html',
  styleUrls: ['./participating-stores.component.scss']
})
export class ParticipatingStoresComponent implements OnInit {
  public language: any;
  
  constructor() { }

  ngOnInit() {
    if(localStorage.getItem('lang')){
      this.language = localStorage.getItem('lang');
    }else{
      this.language = 'EN';
    }
  }

}
