import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AccountRegisterComponent } from "./account/account-register/account-register.component";
import { AuthGuard } from "./_guards/auth.guard";
import { isLoggedIn } from "./_guards/isLoggedin.guard";
import { referralValid } from "./_guards/registration-code.guard";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./auth/login/login.component";
import { UserResolver } from "./_helpers/user.resolver";
import { BaseComponent } from "./base/base.component";
import { ProfileComponent } from "./profile/profile.component";
import { TransactionTableComponent } from "./_ui/components/transaction-table/transaction-table.component";
import { VerifyComponent } from "./auth/verify/verify.component";
import { AvatarComponent } from "./_ui/components/avatar/avatar.component";
import { PasswordResetComponent } from "./auth/password-reset/password-reset.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { ActivateComponent } from "./auth/activate/activate.component";
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { RemittanceComponent } from './remittance/remittance.component';
import { ParticipatingStoresComponent } from './participating-stores/participating-stores.component';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { ReferralCodeComponent } from './referral-code/referral-code.component';
import { resendVerification } from './_guards/registration-resend.guard';
import { ResendEmailComponent } from './account/account-register/resend-email/resend-email.component';
import { ServiceProvidersComponent } from './service-providers/service-providers.component';


const routes: Routes = [
  {
    path: "register/success", component: ResendEmailComponent,
    data: {title: "Register"},
    canActivate: [isLoggedIn, resendVerification],
    runGuardsAndResolvers: 'always'
  },
  {
    path: "register", component: AccountRegisterComponent,
    data: {title: "Register"},
    // canActivate: [isLoggedIn, referralValid],
    canActivate: [referralValid],
    runGuardsAndResolvers: 'always'
  },
  {
    path: "referral-code", component: ReferralCodeComponent,
    // data: {title: "Referral Code"},
    // canActivate: [isLoggedIn],
    runGuardsAndResolvers: 'always'
  },
  {
    path: "referral-code/:ref_code", component: ReferralCodeComponent,
    // data: {title: "Referral Code"},
    // canActivate: [isLoggedIn],
    runGuardsAndResolvers: 'always'
  },
  {
    path: "activate", component: ActivateComponent,
    data: {title: "Activate Account"},
    canActivate: [isLoggedIn],
    runGuardsAndResolvers: 'always'
  },
  {
    path: "verify", component: VerifyComponent,
    data: {title: "Verify Account"},
    canActivate: [isLoggedIn],
    runGuardsAndResolvers: 'always'
  },
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    resolve: { user: UserResolver },
    children: [
      {
        path: "",
        component: DashboardComponent,
        data: {title: "Dashboard"},
        pathMatch: "full",
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        resolve: { user: UserResolver }
      },
      // {
      //   path: "about-us",
      //   component: AboutComponent,
      //   data: {title: "About Us"},
      //   canActivate: [AuthGuard],
      //   runGuardsAndResolvers: 'always',
      //   resolve: { user: UserResolver }
      // },
      {
        path: "faq",
        component: FaqComponent,
        canActivate: [AuthGuard],
        data: {title: "FAQ"},
        runGuardsAndResolvers: 'always',
        resolve: { user: UserResolver }
      },
      {
        path: "profile",
        component: ProfileComponent,
        data: {title: "Profile"},
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        resolve: { user: UserResolver }
      },
      {
        path: "transactions",
        component: TransactionTableComponent,
        data: {title: "Transactions"},
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        resolve: { user: UserResolver }
      },
      {
        path: "profile/change-password",
        component: ChangePasswordComponent,
        data: {title: "Change Password"},
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        resolve: { user: UserResolver }
      },
      {
        path: "remittance",
        component: RemittanceComponent,
        data: {title: "Remittance"},
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        resolve: { user: UserResolver }
      },
      {
        path: "participating-stores",
        component: ParticipatingStoresComponent,
        data: {title: "Participating Stores"},
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: "service-providers",
        component: ServiceProvidersComponent,
        data: {title: "Service Providers"},
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always'
      },
    ]
  },
  // { path: 'photo', component: AvatarComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
  {
    path: "login", component: LoginComponent,
    data: {title: "Log in"},
    canActivate: [isLoggedIn],
    runGuardsAndResolvers: 'always'
  },
  {
    path: "forgot-password", component: ForgotPasswordComponent,
    data: {title: "Forgot Password"},
    canActivate: [isLoggedIn],
    runGuardsAndResolvers: 'always'
  },
  {
    path: "reset-password", component: PasswordResetComponent,
    data: {title: "Reset Password"},
    canActivate: [isLoggedIn],
    runGuardsAndResolvers: 'always'
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
