import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remittance',
  templateUrl: './remittance.component.html',
  styleUrls: ['./remittance.component.scss']
})
export class RemittanceComponent implements OnInit {
  public language: any;
  
  constructor() { }

  ngOnInit() {
    if(localStorage.getItem('lang')){
      this.language = localStorage.getItem('lang');
    }else{
      this.language = 'EN';
    }

  }

}
