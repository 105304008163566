import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/service/user.service';
import { NgxSpinnerService } from "ngx-spinner";

export interface ModalContent{
  user: any,
  content: string,
  title: string,
  type: string,
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  dialogData: any;
  referral_link;

  constructor(
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ModalComponent>,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: ModalContent
  ) {

    this.referral_link = environment.domain + "/referral-code/" + this.data.user.referral_code;

   }

  ngOnInit() {
    this.dialogData = this.data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public copyReferralCode() {
    return this.referral_link;
  }

  // copy shortcut
  public notify(event: string): void {

    this.toastr.success(
      "Referral Link Copied", "",
      {
        positionClass: "toast-top-right",
        // closeButton: true,
        // timeOut: 3000,
        // easeTime: 3000
      }
    );
  }

  activate() {
    this.userService.activate().subscribe(
      response => {
        // localStorage.setItem("Status", "Account Activated");
        // location.reload();
        // this.router.navigate(["/"]);
        // reload data
        this.userService.refreshData$.next();
        this.router.navigate([this.router.url]);
        this.spinner.hide();
        this.toastr.success(
          "You have successfully activated your account.",
          "Congratulations",
          {
            positionClass: "toast-top-right",
            closeButton: true,
            timeOut: 3000
          }
        );
      },
      error => {
        this.router.navigate(["/"]);
        this.spinner.hide();
      }
    );
  }
  

}
