import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UserService } from 'src/app/service/user.service';


export interface ModalContent{
  user: any
}

@Component({
  selector: 'app-complete-profile-modal',
  templateUrl: './complete-profile-modal.component.html',
  styleUrls: ['./complete-profile-modal.component.scss']
})
export class CompleteProfileModalComponent implements OnInit {
  dialogData: any;

  constructor(
    private userService: UserService,
    private router: Router,
    public dialogRef: MatDialogRef<CompleteProfileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalContent
  ) { }

  ngOnInit() {
    this.dialogData = this.data;
  }

}
