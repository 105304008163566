import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material";
import { AccountRegisterComponent } from "./account/account-register/account-register.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CustomValidators } from "./validators/custom-validators";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./auth/login/login.component";
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { AlertComponent } from "./alert/alert.component";
import { CookieService } from "ngx-cookie-service";
import { FlexLayoutModule } from "@angular/flex-layout";
import { UiModule } from "./_ui/ui.module";
import { UserResolver } from "./_helpers/user.resolver";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { BaseComponent } from "./base/base.component";
import { ProfileComponent } from "./profile/profile.component";
import { SidebarMenuComponent } from "./sidebar-menu/sidebar-menu.component";
import { FooterComponent } from "./footer/footer.component";

import { ToastrModule } from "ngx-toastr";
import { ModalComponent } from "./_ui/components/modal/modal.component";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { CopyClipboardDirective } from "./_helpers/copy-clipboard.directive";
import { NgKnifeModule } from "ng-knife";

import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";

import { RouterModule } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
import { VerifyComponent } from "./auth/verify/verify.component";
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { PasswordResetComponent } from "./auth/password-reset/password-reset.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { ActivateComponent } from "./auth/activate/activate.component";

import { NgxPayPalModule } from "ngx-paypal";
import { UserService } from './service/user.service';
import { WebsocketService } from './service/websocket.service';
import { ConvertPointsFormComponent } from './account/convert-points-form/convert-points-form.component';

import { DataTablesModule } from 'angular-datatables';
import { ChangePasswordComponent } from './account/change-password/change-password.component';

import { NativeDateAdapter, MAT_DATE_FORMATS, DateAdapter } from "@angular/material";
import { AppDateAdapter, APP_DATE_FORMATS } from "./_helpers/date-adapter";
import { DatePipe } from '@angular/common';
import { RemittanceComponent } from './remittance/remittance.component';
import { ParticipatingStoresComponent } from './participating-stores/participating-stores.component';
import { CopayDialogComponent } from './copay-dialog/copay-dialog.component';
import { HttpClient } from "@angular/common/http";
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaskModule } from 'ngx-mask';
import { ReferralCodeComponent } from './referral-code/referral-code.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { BuyerComponent } from './profile/buyer/buyer.component';
import { SellerComponent } from './profile/seller/seller.component';
import { ProfileDirective } from './profile/profile.directive';
import { AccountRegisterDirective } from './account/account-register/account-register.directive';
import { SellerRegisterComponent } from './account/account-register/seller-register/seller-register.component';
import { BuyerRegisterComponent } from './account/account-register/buyer-register/buyer-register.component';
import { ResendEmailComponent } from './account/account-register/resend-email/resend-email.component';
import { ServiceProvidersComponent } from './service-providers/service-providers.component';

@NgModule({
  declarations: [
    AppComponent,
    AccountRegisterComponent,
    DashboardComponent,
    LoginComponent,
    AlertComponent,
    ToolbarComponent,
    BaseComponent,
    ProfileComponent,
    SidebarMenuComponent,
    FooterComponent,
    ModalComponent,
    CopyClipboardDirective,
    VerifyComponent,
    PasswordResetComponent,
    ForgotPasswordComponent,
    ActivateComponent,
    ConvertPointsFormComponent,
    ChangePasswordComponent,
    RemittanceComponent,
    ParticipatingStoresComponent,
    CopayDialogComponent,
    AboutComponent,
    FaqComponent,
    ReferralCodeComponent,
    TermsAndConditionsComponent,
    BuyerComponent,
    SellerComponent,
    ProfileDirective,
    AccountRegisterDirective,
    SellerRegisterComponent,
    BuyerRegisterComponent,
    ResendEmailComponent,
    ServiceProvidersComponent
  ],
  imports: [
    NgxMaskModule.forRoot(),
    NgxMatSelectSearchModule,
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    UiModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true
    }), // ToastrModule added,
    NgKnifeModule,
    LoadingBarModule,
    RouterModule,
    LoadingBarRouterModule, // for router loading,
    NgxSpinnerModule,
    MatPasswordStrengthModule,
    NgxPayPalModule,
    DataTablesModule,
  ],
  entryComponents: [ModalComponent,
    ConvertPointsFormComponent,
    CopayDialogComponent,
    TermsAndConditionsComponent,
    BuyerComponent, SellerComponent,
    BuyerRegisterComponent, SellerRegisterComponent],
  providers: [
    DatePipe,
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    },
    UserResolver,
    CookieService,
    UserService,
    // WebsocketService,
    CustomValidators,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
