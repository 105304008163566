import { NgModule } from '@angular/core';
import * as Material from '@angular/material';

@NgModule({
    imports: [
      Material.MatCheckboxModule,
      Material.MatCheckboxModule,
      Material.MatButtonModule,
      Material.MatInputModule,
      Material.MatAutocompleteModule,
      Material.MatDatepickerModule,
      Material.MatFormFieldModule,
      Material.MatRadioModule,
      Material.MatSelectModule,
      Material.MatSliderModule,
      Material.MatSlideToggleModule,
      Material.MatMenuModule,
      Material.MatSidenavModule,
      Material.MatToolbarModule,
      Material.MatListModule,
      Material.MatGridListModule,
      Material.MatCardModule,
      Material.MatStepperModule,
      Material.MatTabsModule,
      Material.MatExpansionModule,
      Material.MatButtonToggleModule,
      Material.MatChipsModule,
      Material.MatIconModule,
      Material.MatProgressSpinnerModule,
      Material.MatProgressBarModule,
      Material.MatDialogModule,
      Material.MatTooltipModule,
      Material.MatSnackBarModule,
      Material.MatTableModule,
      Material.MatSortModule,
      Material.MatPaginatorModule,
      Material.MatNativeDateModule,
      Material.MatSnackBarModule,
      Material.MatBadgeModule
    ],
    exports: [
      Material.MatCheckboxModule,
      Material.MatCheckboxModule,
      Material.MatButtonModule,
      Material.MatInputModule,
      Material.MatAutocompleteModule,
      Material.MatDatepickerModule,
      Material.MatFormFieldModule,
      Material.MatRadioModule,
      Material.MatSelectModule,
      Material.MatSliderModule,
      Material.MatSlideToggleModule,
      Material.MatMenuModule,
      Material.MatSidenavModule,
      Material.MatToolbarModule,
      Material.MatListModule,
      Material.MatGridListModule,
      Material.MatCardModule,
      Material.MatStepperModule,
      Material.MatTabsModule,
      Material.MatExpansionModule,
      Material.MatButtonToggleModule,
      Material.MatChipsModule,
      Material.MatIconModule,
      Material.MatProgressSpinnerModule,
      Material.MatProgressBarModule,
      Material.MatDialogModule,
      Material.MatTooltipModule,
      Material.MatSnackBarModule,
      Material.MatTableModule,
      Material.MatSortModule,
      Material.MatPaginatorModule,
      Material.MatNativeDateModule,
      Material.MatSnackBarModule,
      Material.MatBadgeModule
    ],
    providers: [
      Material.MatDatepickerModule,
    ]
})

export class MaterialModule {}