import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalComponent } from './components/modal/modal.component';


@Injectable({
  providedIn: 'root'
})
export class ModalService {
  width: string;
  points: Number;

  constructor(private dialog: MatDialog) { }

  showDialog(user) {
    const dialogRef = this.dialog.open(ModalComponent, {
      // width: this.width,
      disableClose: true,
      data: {user: user},
      panelClass: 'custom-dialog-card'
  });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
}
