import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-providers',
  templateUrl: './service-providers.component.html',
  styleUrls: ['./service-providers.component.scss']
})
export class ServiceProvidersComponent implements OnInit {
  public language: any;
  constructor() { }

  ngOnInit() {
    if(localStorage.getItem('lang')){
      this.language = localStorage.getItem('lang');
    }else{
      this.language = 'EN';
    }
  }

}
