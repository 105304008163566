import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient, ) {
  }

  public getJSON(): Observable<any> {

    return this.http.get("./assets/provinces.json");
  }

  public getCities(): Observable<any> {
    return this.http.get("./assets/cities.json");
  }

  public getUSstates(): Observable<any> {
    return this.http.get("./assets/usa.json");
  }
}
