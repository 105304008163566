import { Component, OnInit, OnDestroy, ComponentFactoryResolver } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Member } from "src/app/model/member";
import {
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
  FormBuilder
} from "@angular/forms";
import { CustomValidators } from "src/app/validators/custom-validators";
import { ErrorStateMatcher, MatSnackBar } from "@angular/material";
import { UserService } from "src/app/service/user.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import { ReplaySubject, Subject } from 'rxjs';
import {debounceTime, delay, tap, filter, map, takeUntil} from 'rxjs/operators';
import { MatDialog } from "@angular/material";
import { TermsAndConditionsComponent } from 'src/app/terms-and-conditions/terms-and-conditions.component';

import { State, STATES } from 'src/app/model/state';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || isSubmitted));
  }
}

@Component({
  selector: 'app-buyer',
  templateUrl: './buyer.component.html',
  styleUrls: ['./buyer.component.scss']
})
export class BuyerComponent implements OnInit, OnDestroy {

  user: any;
  public member: Member;
  public profileForm: FormGroup;
  submitted = false;
  autoActivation: boolean;
  // loading = false;
  genders: string[] = ['male', 'female', 'Others'];

  checkAgreement: boolean;

  yearNow = new Date().getFullYear() - 5;
  maxDate = new Date(this.yearNow, 0, 1);
  navigationSubscription;
  model: any = {};
  country_code;



  /** list of states */
  protected states: State[] = STATES;

  /** control for filter for server side. */
  public stateServerSideFilteringCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching: boolean = false;

  /** list of states filtered after simulating server side search */
  public  filteredServerSideStates: ReplaySubject<State[]> = new ReplaySubject<State[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {
    this.country_code = environment.country_code;
    this.searchStates();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        // this.initConfig();
        this.getUser();
      }
    });
  }

  // Custom mat-error validation
  matcher = new MyErrorStateMatcher();

  changeCheck(){
    this.checkAgreement = !this.checkAgreement;
  }
 
  updateProfile() {
    this.submitted = true;

    this.spinner.show("content-spinner");

    this.member = Object.assign({}, this.model);
    this.userService.updateUser(this.member).subscribe(
      data => {
        // console.log(data)
        this.spinner.hide("content-spinner");
        // localStorage.setItem("Status", "Profile updated");
        // location.reload();
        this.toastr.success("Profile updated", "", {
          positionClass: "toast-top-right",
          closeButton: true
        });
        this.router.navigate([this.router.url]);
      },
      error => {
        this.spinner.hide("content-spinner");
      }
    );
  }

    /** listen for search value in states */
    searchStates(){
      // listen for search field value changes
      this.stateServerSideFilteringCtrl.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => this.searching = true),
          takeUntil(this._onDestroy),
          debounceTime(200),
          map(search => {
            if (!this.states) {
              return [];
            }
            // simulate server fetching and filtering data
            return this.states.filter(state => state.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
          }),
          delay(500)
        )
        .subscribe(filteredstates => {
          this.searching = false;
          this.filteredServerSideStates.next(filteredstates);
        },
          error => {
            // no errors in our simulated example
            this.searching = false;
            // handle error...
          });
    }

  ngOnDestroy(){
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    this._onDestroy.next();
    this._onDestroy.complete();

    if (this.navigationSubscription) {  
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {    
    // Refetch user data on update/save/post/
    this.userService.refreshData$.subscribe(() => {
      this.getUser();
      // location.reload();

      if (localStorage.getItem("Status")) {
        this.toastr.success("Profile updated", "", {
          positionClass: "toast-top-right",
          closeButton: true
        });
        localStorage.removeItem("Status");
      }
    });
    this.loadUser();
  }

  private getUser() {
    this.userService.getUser().subscribe((user: any) => {
      this.user = user;
      // this.profileForm.patchValue(this.user);
      // initialize form values
      this.model = Object.assign(this.model, this.user);
      this.autoActivation = this.user.auto_activation;
    });
  }

  private loadUser() {
    this.route.data.subscribe((data: { user: any }) => {
      this.user = data.user;
      // this.profileForm.patchValue(this.user);
      this.model = Object.assign(this.model, this.user);
      this.autoActivation = this.user.auto_activation;
    });
  }

  setValue(e){
    if(e.checked){
      this.autoActivation = true
      this.userService.autoActivation("True").subscribe(
        response => {
          this.userService.refreshData$.next();
          this.router.navigate([this.router.url]);
          this.spinner.hide();
          this.toastr.success(
            "Auto-activation is turned-on.", "",
            {
              positionClass: "toast-top-right",
              closeButton: true,
              // timeOut: 3000,
              easeTime: 3000
            }
          );
        },
        error => {
          console.log(error);
          this.spinner.hide();
        }
      );
    }else{
      this.autoActivation = false
      this.userService.autoActivation("False").subscribe(
        response => {
          this.userService.refreshData$.next();
          this.router.navigate([this.router.url]);
          this.spinner.hide();
          this.toastr.success(
            "Auto-activation is turned off.", "",
            {
              positionClass: "toast-top-right",
              closeButton: true,
              // timeOut: 3000,
              easeTime: 3000
            }
          );
        },
        error => {
          console.log(error);
          this.spinner.hide();
        }
      );
    }
  }

  termsAndConditions(){
    const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
      width: '500px',
      data: {}
    });
  }

}
