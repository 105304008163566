import { Component, OnInit } from '@angular/core';
import { ReadMoreDialogComponent } from '../_ui/components/read-more-dialog/read-more-dialog.component';
import { MatDialog } from "@angular/material";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  whatClick(){
    let readMore = "<p>CostCut Club is dubbed as the UBER for retail stores.</p>" + 
    "<p>It is primarily designed to bring back customers to merchants who are struggling to float their sales and be able to combat the effect brought about by the proliferation of huge chain-of-stores and giant online retailers like Amazon.</p>" +
    "<p>CostCut Club simply serves as a venue to connect the buyers to the sellers in an easy to manage one stop all-in-one centralized platform for seamless navigation.</p>"+
    "<p>The idea is to use a reloadable debit card that serves as a membership card for each customer.</p>"+
    "<p>Club members are able to generate reward points by participating in a customer referral system.</p>"+
    "<p>The collective points is pooled together and shared back among the club members themselves.</p>"+
    "<p>Everyone is programmed to earn points one at a time until it reaches a total 243 points.</p>"+
    "<p>You can convert the cash value of the points into a PREPAID LOAD  by performing a 50-50 CO-SHARE TRANSACTION.</p>"+
    "<p>The membership card is close loop so that the prepaid load is CONSUMABLE ONLY at any participating stores of CostCut Club.</p>";

    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      width: '500px',
      data: {
        title: "What is CostCut Club?",
        content: readMore,
        header: "assets/img/about-1.jpg",
      }
    });
  }

  wareHouseClick(){
    let readMore = "<p>We are going to partner with wholesalers or warehouses that are locally available in each area to supply the needs of our participating stores within the community.</p>"+
    "<p>We are also going to launch soon a new component of the CostCut Club Shopping Network called the <b>PICKnGO</b> Business Model which is in the form of a <b>FRANCHISE BUSINESS</b>.</p>"+
    "<p>It is designed for our club members who wants to start a business of their own. </p>"+
    "<p>It is going to operate like a COOPERATIVE STYLE BUSINESS wherein each PICKnGo UNIT is own by the club members THEMSELVES in any given location.</p>"+
    "<p>The OWNERS will operate THE UNIT as an INDEPENDENT DISTRIBUTOR of the local warehouse near them.</p>"+
    "<p>Customers can shop online by browsing the website of the local PICKnGo UNIT.</p>"+
    "<p>The actual goods however are available at the warehouse.</p>"+
    "<p>The warehouse/supplier will deliver the goods IN BOXES at THE UNIT which serves as a DROP-OFF CENTER.</p>"+
    "<p>Upon arrival, the customer will receive a text message from The Unit that their ORDER IS NOW READY FOR PICK-UP. </p>"+
    "<p>The customer must perform an arrangement in the SCHEDULER TAB.</p>"+
    "<p>The customers have an option to pick-up the goods themselves or have the local PICKnGo UNIT deliver the goods door to door for an additional fee.</p>"+
    "<p>All PICKnGo UNITS are considered as participating store of CostCut Club in their respective  area of operation.</p>"+
    "<p>PICKnGo UNITS are primarily  designed to serve far flung or remote areas where we have considerable number of club members but has commercial district nearby to serve them.</p>";

    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      width: '500px',
      data: {
        title: "How do we serve the community?",
        content: readMore,
        header: "assets/img/about-2.jpg",
      }
    });
  }

  ourTeamClick(){
    let readMore = '<p>The CostCut Club Shopping Network is composed of dedicated group of individuals with the aim to BRIDGE THE GAP in order to deliver the following:</p>'+
    '<p>1. QUALITY SERVICE </p>'+
    '<p>2. EFFICIENT CHAIN OF SUPPLY</p>'+
    '<p>3. CENTRALIZED MARKETPLACE</p>'+  
    '<p>4. EQUAL CHANCE IN BUSINESS</p>'+
    '<p>5. REDUCED COST OF SHOPPING</p>'+
    '<p>6. SALES ENHANCEMENT</p>'+  
    '<p>7. PROFIT SHARING</p>'+
    '<p>8. EFFECTIVE GENERATION OF CUSTOMERS</p>'+
    '<p>9. CUSTOMER RETENTION</p>'+
    '<p>10. AFFORDABILITY OF CONSUMER GOODS</p>'+
    '<p>We strongly believe that a strong grassroot community is attainable and doable in this age of rapid advancement in technology and creative marketing strategy.</p>'+
    '<p>Our AFFILIATE GROUP OF COMPANIES, SMALL BUSINESS OWNERS and most of all, OUR VALUED CLUB MEMBERS are solidly united in the spirit of camaraderie like FAMILY in real sense of the word and in the most meaningful way possible.</p>'+
    '<p>AS A TEAM MEMBER our goal is to achieve a remarkable business ethics where each component is an important part of our organization and each individual plays an integral role without bias to race, color, religious affiliation, sexual orientation and any political views.</p>'+
    '<p>WE BELIEVE in working together as a whole.</p>'+
    '<p>Where everyone deserves a chance to shine and wherein their respective family CAN AFFORD basic needs without sacrificing quality of goods and excelent service.</p>'+
    '<p>At CostCut Club, our slogan will always be:</p>'+
    '<p class="text-center"><em>"BRIDGING THE NEEDS TO SERVE YOU BETTER"</em></p>';
    
    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      width: '500px',
      data: {
        title: "Who are we?",
        content: readMore,
        header: "assets/img/about-3.jpg",
      }
    });
  }

}
