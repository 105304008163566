import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/service/authentication.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { environment } from "src/environments/environment";
import { CustomValidators } from "../../validators/custom-validators";
import { UserService } from "src/app/service/user.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: "app-change-password",
	templateUrl: "./change-password.component.html",
	styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
	changePasswordForm: FormGroup;
	loading = false;
	unhide: boolean;
	confirm_password_unhide: boolean;
	old_password_unhide: boolean;

	constructor(
		private authenticationService: AuthenticationService,
		private fb: FormBuilder,
		private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
	) {
		this.changePasswordForm = this.fb.group(
			{
				password: [
					"",
					[
						// 1. Password Field is Required
						Validators.required,
						// 2. check whether the entered password has a number
						CustomValidators.patternValidator(/\d/, { hasNumber: true }),
						// 3. check whether the entered password has upper case letter
						// CustomValidators.patternValidator(/[A-Z]/, {
						// 	hasCapitalCase: true
						// }),
						// // 4. check whether the entered password has a lower-case letter
						// CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
						// // 5. check whether the entered password has a special character
						// CustomValidators.patternValidator(
						// 	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[^\sa-zA-Z0-9]).{8,}/,
						// 	{ hasSpecialCharacters: true }
						// ),
						Validators.minLength(8)
					]
				],
				confirmPassword: ["", [Validators.required]],
				oldPassword: ["", [Validators.required]]
			},
			{
				validator: CustomValidators.passwordMatchValidator
			}
		);
	}

	ngOnInit() {}

	onSubmit() {
		// stop here if form is invalid
		this.loading = true;

		if (this.changePasswordForm.invalid) {
			return;
		}
		const formData = {
			"old_password": this.changePasswordForm.get("oldPassword").value,
			"new_password": this.changePasswordForm.get("password").value
		};
		this.userService.changePassword(formData).subscribe(
			res => {
				// this.response = res;
				console.log(res);
				if (res["status"] == "400") {
					// set error message from server side validation
					const formControl = this.changePasswordForm.get(
						"oldPassword"
					);
					if (formControl) {
						this.loading = false;
						formControl.setErrors({
							serverError: res['errors']
						});
					}
					this.loading = false;
				} else {
					this.loading = false;
          this.toastr.success("Password successfully updated", "", {
            positionClass: "toast-top-right",
            closeButton: true
          });
          this.router.navigate(['/']);
				}
			},
			err => {
				console.log(err);
				this.loading = false;
				// console.log(err);
			}
		);
	}
}
