import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '.././service/authentication.service';

@Injectable({ providedIn: 'root' })
export class isLoggedIn implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            //loggedin so redirect to dashboard
            this.router.navigate(['/']);
            return false;
        }

        return true;
    }
}