
export interface State {
    name: string;
  }
  
  /** list of states */
  export const STATES: State[] = [

    { name: "Metro Manila" },
  
    { name: "Abra" },
    { name: "Apayao" },
    { name: "Benguet" },
    { name: "Ifugao" },
    { name: "Kalinga" },
    { name: "Mountain Province" },
  
    { name: "Ilocos Norte" },
    { name: "Ilocos Sur" },
    { name: "La Union" },
    { name: "Pangasinan" },
  
    { name: "Batanes" },
    { name: "Cagayan" },
    { name: "Isabela" },
    { name: "Nueva Vizcaya" },
    { name: "Quirino" },
  
    { name: "Aurora" },
    { name: "Bataan" },
    { name: "Bulacan" },
    { name: "Nueva Ecija" },
    { name: "Pampanga" },
    { name: "Tarlac" },
    { name: "Zambales" },
  
    { name: "Batangas" },
    { name: "Cavite" },
    { name: "Laguna" },
    { name: "Quezon" },
    { name: "Rizal" },
  
    { name: "Marinduque" },
    { name: "Occidental Mindoro" },
    { name: "Oriental Mindoro" },
    { name: "Palawan" },
    { name: "Romblon" },
  
    { name: "Albay" },
    { name: "Camarines Norte" },
    { name: "Camarines Sur" },
    { name: "Catanduanes" },
    { name: "Masbate" },
    { name: "Sorsogon" },
  
    { name: "Aklan" },
    { name: "Antique" },
    { name: "Capiz" },
    { name: "Guimaras" },
    { name: "Iloilo" },
    { name: "Negros Occidental" },
  
    { name: "Bohol" },
    { name: "Cebu" },
    { name: "Negros Oriental" },
    { name: "Siquijor" },
  
    { name: "Biliran" },
    { name: "Eastern Samar" },
    { name: "Leyte" },
    { name: "Northern Samar" },
    { name: "Samar" },
    { name: "Southern Leyte" },
  
    { name: "Zamboanga del Norte" },
    { name: "Zamboanga del Sur" },
    { name: "Zamboanga Sibugay" },
  
    { name: "Bukidnon" },
    { name: "Camiguin" },
    { name: "Lanao del Norte" },
    { name: "Misamis Occidental" },
    { name: "Misamis Oriental" },
  
    { name: "Compostela Valley" },
    { name: "Davao del Norte" },
    { name: "Davao del Sur" },
    { name: "Davao Occidental" },
    { name: "Davao Oriental" },
  
    { name: "Cotabato" },
    { name: "Sarangani" },
    { name: "South Cotabato" },
    { name: "Sultan Kudarat" },
  
    { name: "Agusan del Norte" },
    { name: "Agusan del Sur" },
    { name: "Dinagat Islands" },
    { name: "Surigao del Norte" },
    { name: "Surigao del Sur" },
  
    { name: "Basilan" },
    { name: "Lanao del Sur" },
    { name: "Maguindanao" },
    { name: "Sulu" },
    { name: "Tawi-tawi" }
  ]