import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(1),
            catchError(err => {
            if (err.status === 401 && this.authenticationService.currentUserValue) {
            //     // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);

            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}