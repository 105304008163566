import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthenticationService } from '../service/authentication.service';
import { Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-referral-code',
  templateUrl: './referral-code.component.html',
  styleUrls: ['./referral-code.component.scss']
})
export class ReferralCodeComponent implements OnInit {
  /** referral code */
  public referralCode: FormControl = new FormControl();
  loading = false;
  currentUser;

  constructor(private auth: AuthenticationService,
    private cookie: CookieService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router) {
      this.currentUser = this.auth.currentUserValue;
     }

  ngOnInit() {
    // referral link option
    this.route.params.subscribe((params) => {
      if(params['ref_code']){
        var ref_code = params['ref_code'];
        this.loading = true;
        this.auth.verifyReferralCode(ref_code).subscribe((resp:any)=>{
          this.loading = false;
          if(resp.status=='OK'){
            localStorage.setItem('inviterDetails', resp.inviter);
            // this.cookie.set('inviterDetails', resp.inviter);
            localStorage.setItem('referralCode', ref_code);
            // this.cookie.set('referralCode', ref_code);
            this.router.navigate(['register']);
          }else{
            this.toastr.error("Referral Code Doesn't Exist", 'Invalid Referral Link',
            {
                positionClass: 'toast-top-center',
                closeButton: true
            });
            this.router.navigate(['referral-code']);
          }
        }, (err)=>{
          this.loading = false;
        })
      }
    });
    
    if(localStorage.getItem('inviterDetails')){
      this.router.navigate(['register'])
    }
  }

  verifyReferralCode(){
    this.loading = true;
    this.auth.verifyReferralCode(this.referralCode.value).subscribe((resp:any)=>{
      this.loading = false;
      if(resp.status=='OK'){
        localStorage.setItem('inviterDetails', resp.inviter);
        localStorage.setItem('referralCode', this.referralCode.value);
        // this.cookie.set('inviterDetails', resp.inviter);
        // this.cookie.set('referralCode', this.referralCode.value);
        this.router.navigate(['register']);
      }else{
        this.referralCode.setErrors({invalidCode: true});
      }
    }, (err)=>{
      this.loading = false;
    })
  }

  logout() {
    this.auth.logout();
    location.reload(true);
  }

}
