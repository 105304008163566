import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from '../service/alert.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  private subscription: Subscription;
  message: any;

  constructor(private alertService: AlertService,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
    this.alertService.getAlert()
      .subscribe(message => {
        switch (message && message.type) {
          case 'success':
            message.cssClass = 'alert alert-success';
            break;
          case 'error':
            message.cssClass = 'alert alert-danger';
            break;
        }

        this.message = message;
        if(this.message){
          this.toastr.success(this.message.text,'',
          {
            positionClass: 'toast-top-center',
            closeButton: true
          });
        }
      });
  }


  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}
