import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/service/user.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-transaction-table",
  templateUrl: "./transaction-table.component.html",
  styleUrls: ["./transaction-table.component.scss"]
})
export class TransactionTableComponent implements OnInit {
  constructor(
    private userService: UserService,
    private cookie: CookieService
  ) {}

  ngOnInit() {
    let token = this.cookie.get("token");
    $(function() {
      // let httpOptions = {
      //   headers: new HttpHeaders({
      //     "Content-Type": "application/json",
      //     Authorization: `${token}`
      //   })
      // };
      const table: any = $("#example1");
      table.DataTable({
        order: [[ 0, "desc" ]],
        paging: true,
        lengthChange: true,
        searching: true,
        ordering: true,
        info: true,
        autoWidth: true,
        ajax: {
          beforeSend: function(request) {
            // request.setRequestHeader("Content-Type", "application/json");
            request.setRequestHeader("Authorization", token);
          },
          url: `${environment.apiEndpoint}/api/user/transactions/?format=json`,
          type: "GET"
        },
        columns: [
          {
            data: "date_created",
            // convert timestamp to date
            render: function(data) {
              var date = new Date(data);
              var month = date.getMonth() + 1;
              return ( date
                // (month.toString().length > 1 ? month : "0" + month) +
                // "/" +
                // date.getDate() +
                // "/" +
                // date.getFullYear() +
                // " - " +
                // date.getHours() +
                // ":" +
                // date.getMinutes() +
                // ":" +
                // date.getSeconds()
              );
            }
          },
          { data: "description" }
        ]
      });
    });
  }
}
