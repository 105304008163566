import { Component, ViewChild, OnInit } from "@angular/core";
import { Member } from "./model/member";
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationEnd,
  ActivatedRoute
} from "@angular/router";
import { AuthenticationService } from "./service/authentication.service";
import { ToastrService } from "ngx-toastr";

// import { LoadingBarService } from '@ngx-loading-bar/core';

import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { NgxSpinnerService } from "ngx-spinner";
import { TitleService } from "./service/title.service"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  currentUser: Member;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    // private spinnerOverlay: SpinnerOverlayService,
    // private loadingBar: LoadingBarRouterModule
    private spinner: NgxSpinnerService,
    private titleService: TitleService
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
  }

  ngOnInit(): void {
    this.titleService.init();
    this.route.queryParams.subscribe(params => {
      if (params.login === "true") {
        let url: string = this.router.url.substring(
          0,
          this.router.url.indexOf("?")
        );
        this.router.navigateByUrl(url);
        this.spinner.hide();
      }
    });

    // if (localStorage.getItem("Status") == "Account Activated") {
    //   this.toastr.success(
    //     "You have successfully activated your account.",
    //     "Congratulations",
    //     {
    //       positionClass: "toast-top-center",
    //       closeButton: true,
    //       timeOut: 7000
    //     }
    //   );
    //   localStorage.removeItem("Status");
    // }
  }

  // ngAfterViewInit() {
  //   this.router.events
  //     .subscribe((event) => {
  //       if (event instanceof NavigationStart) {
  //         // this.spinnerOverlay.show();
  //         this.loadingBar.start();
  //       }
  //       else if (
  //         event instanceof NavigationEnd ||
  //         event instanceof NavigationCancel
  //       ) {
  //         // this.spinnerOverlay.hide();
  //         this.loadingBar.complete();
  //       }
  //     });
  // }
}
