import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[register-directive]'
})
export class AccountRegisterDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
