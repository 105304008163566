import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  public language: any;

  constructor() { }

  ngOnInit() {
    if(localStorage.getItem('lang')){
      this.language = localStorage.getItem('lang');
    }else{
      this.language = 'EN';
    }
  }



}
