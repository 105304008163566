import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../service/authentication.service';
import { CookieService } from "ngx-cookie-service";

@Injectable({ providedIn: 'root' })
export class resendVerification implements CanActivate {
    constructor(
        private router: Router,
        private cookie: CookieService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const resendEmailToken = localStorage.getItem('emailToken');
        if (resendEmailToken) {
            // authorised so return true
            return true;
        }

        //redirect to referral code page if no referral details on cookie
        this.router.navigate(['/referral-code']);
        return false;
    }
}