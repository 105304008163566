import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/service/user.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-activate",
  templateUrl: "./activate.component.html",
  styleUrls: ["./activate.component.scss"]
})
export class ActivateComponent implements OnInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.activate();
  }

  activate() {
    this.userService.activate().subscribe(
      response => {
        localStorage.setItem("Status", "Account Activated");
        location.reload();
        this.router.navigate(["/"]);
        this.spinner.hide();
      },
      error => {
        console.log(error);
        this.router.navigate(["/"]);
        this.spinner.hide();
      }
    );
  }
}
