import { Component, OnInit, Input } from "@angular/core";
import { AuthenticationService } from "../service/authentication.service";
import { ActivatedRoute } from "@angular/router";
import { Member } from "../model/member";
import { UserService } from "../service/user.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-sidebar-menu",
  templateUrl: "./sidebar-menu.component.html",
  styleUrls: ["./sidebar-menu.component.scss"]
})
export class SidebarMenuComponent implements OnInit {
  @Input() user: any;
  @Input() country_code: any;
  apiEndpoint = environment.apiEndpoint;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // reinitialize treeview menu
    $(document).ready(() => {
      const trees: any = $('[data-widget="tree"]');
      trees.tree();
    });
  }
}
