import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, Observer } from "rxjs";

import { Member } from "../model/member";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./authentication.service";
import { CookieService } from "ngx-cookie-service";
import { tap, map, flatMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class UserService {
  private httpOptions: any;
  private _refreshData$ = new Subject();

  get refreshData$() {
    return this._refreshData$;
  }

  constructor(private http: HttpClient, private cookie: CookieService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
  }

  // webSocketConnect() {
  //   this.socket.subscribe(
  //     message => {
  //       console.log(message); // or just use it directly
  //     },
  //     error => {
  //       console.error(error); // handle errors
  //     }
  //   );
  // }

  convertPoints(pointsToConvert): Observable<any> {
    let token = this.cookie.get("token");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `${token}`
      })
    };

    return this.http
      .put<any>(
        `${environment.apiEndpoint}/api/user/points/convert/`, {"points_to_convert": pointsToConvert},
        httpOptions
      )
      .pipe(
        tap(() => {
          this._refreshData$.next();
        })
      );
  }

  getUser(): Observable<any> {
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let token = this.cookie.get("token");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `${token}`
      })
    };
    // console.log(token, "hehehe");
    return this.http.get<any>(
      `${environment.apiEndpoint}/api/user`,
      httpOptions
    );
  }

  getTransactions(): Observable<any>{
    let token = this.cookie.get("token");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `${token}`
      })
    };
    return this.http.get<any>(
      `${environment.apiEndpoint}/api/user/transactions/`,
      httpOptions
    );
  }

  getReferrals(): Observable<any>{
    let token = this.cookie.get("token");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `${token}`
      })
    };
    return this.http.get<any>(
      `${environment.apiEndpoint}/api/user/referrals/`,
      httpOptions
    );
  }

  getActivatedReferrals(): Observable<any>{
    let token = this.cookie.get("token");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `${token}`
      })
    };
    return this.http.get<any>(
      `${environment.apiEndpoint}/api/user/referrals/activated/`,
      httpOptions
    );
  }


  changePassword(formData): Observable<any>{
    let token = this.cookie.get("token");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `${token}`
      })
    };
    return this.http.put<any>(`${environment.apiEndpoint}/api/user/password/`, formData, httpOptions)
    .pipe(
      tap(() => {
        this._refreshData$.next();
      })
    );
  }

  updateUser(member: any): Observable<any> {
    let token = this.cookie.get("token");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `${token}`
      })
    };

    return this.http
      .put<any>(`${environment.apiEndpoint}/api/user`, member, httpOptions)
      .pipe(
        tap(() => {
          this._refreshData$.next();
        })
      );
  }

  verifyReferralCode(code){
    return this.http.get(
      environment.apiEndpoint + "/api/verify/referral/code/" + code + "/",
      this.httpOptions
    );
  }

  register(member: any, code): Observable<any> {
    // append code to member
    member.code = code;
    return this.http.post(
      environment.apiEndpoint + "/api/users/",
      member,
      this.httpOptions
    );
  }

  verify(uidb64, token): Observable<any> {
    return this.http.get(
      environment.apiEndpoint + "/validate/" + uidb64 + "/" + token,
      this.httpOptions
    );
  }

  activate(): Observable<any> {
    let token = this.cookie.get("token");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `${token}`
      })
    };

    return this.http.get<Member>(
      `${environment.apiEndpoint}/api/activate/`,
      httpOptions
    );
  }

  autoActivation(activationSetting): Observable<any>{
    let token = this.cookie.get("token");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `${token}`
      })
    };
    return this.http.put<any>(`${environment.apiEndpoint}/api/user/settings/auto_activation/`, {"auto_activation": activationSetting}, httpOptions)
    .pipe(
      tap(() => {
        this._refreshData$.next();
      })
    );
  }

  //  getClientIP(){
  //   let ipAddress:any;
  //   this.http.get<{ip:string}>('https://jsonip.com')
  //   .subscribe( data => {
  //     ipAddress = data
  //     return ipAddress;
  //   })
  // }

}
