import { Component, OnInit, Input, OnDestroy, ComponentFactoryResolver, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Member } from "../model/member";
import { UserService } from "../service/user.service";

import { ProfileItem } from './profile';
import { ProfileDirective } from './profile.directive';
import { BuyerComponent } from './buyer/buyer.component';
import { SellerComponent } from './seller/seller.component';
import { Subject } from 'rxjs';

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit, OnDestroy {
  public profileItem: ProfileItem;
  @ViewChild(ProfileDirective, {static: true}) profileHost: ProfileDirective;

  user: any;
  public member: Member;

  navigationSubscription;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        // this.initConfig();
        this.getUser();
      }
    });
  }

  ngOnDestroy(){
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    this._onDestroy.next();
    this._onDestroy.complete();

    if (this.navigationSubscription) {  
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {    
    // Refetch user data on update/save/post/
    this.userService.refreshData$.subscribe(() => {
      this.getUser();
      // location.reload();
    });
    this.loadUser();
  }

  private getUser() {
    this.userService.getUser().subscribe((user: any) => {
      this.user = user;
      // this.profileForm.patchValue(this.user);
      // initialize form values
      if(this.user.get_user_type_display == "BUYER"){
        this.getBuyerProfile()
      }else{
        this.getSellerProfile()
      }
    });
  }

  private loadUser() {
    this.route.data.subscribe((data: { user: any }) => {
      this.user = data.user;
      // this.profileForm.patchValue(this.user);
      // initialize form values
      if(this.user.get_user_type_display == "BUYER"){
        this.getBuyerProfile()
      }else{
        this.getSellerProfile()
      }
    });
  }


  getBuyerProfile(){
    this.profileItem = new ProfileItem(BuyerComponent);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.profileItem.component);
    const viewContainerRef = this.profileHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
  }

  getSellerProfile(){
    this.profileItem = new ProfileItem(SellerComponent);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.profileItem.component);
    const viewContainerRef = this.profileHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
  }

}
