import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material';
import { DialogComponent } from './components/dialog/dialog.component';
import { TransactionTableComponent } from './components/transaction-table/transaction-table.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgKnifeModule } from 'ng-knife';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ReadMoreDialogComponent } from './components/read-more-dialog/read-more-dialog.component';
import { CompleteProfileModalComponent } from './components/complete-profile-modal/complete-profile-modal.component';

@NgModule({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule, NgKnifeModule],
  declarations: [DialogComponent, TransactionTableComponent, AvatarComponent, ReadMoreDialogComponent, CompleteProfileModalComponent],
  entryComponents: [DialogComponent, ReadMoreDialogComponent, CompleteProfileModalComponent],
  providers: [],
  exports: [TransactionTableComponent, ReactiveFormsModule, FormsModule]
})
export class UiModule {}