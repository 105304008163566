import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";


@Component({
  selector: 'app-copay-dialog',
  templateUrl: './copay-dialog.component.html',
  styleUrls: ['./copay-dialog.component.scss']
})
export class CopayDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<CopayDialogComponent>) { }
  public paymentConfirmed: boolean;

  ngOnInit() {
  }

  pay(){
    this.paymentConfirmed = true;
    this.dialogRef.close();
  }

}
