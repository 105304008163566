import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { PasswordService } from 'src/app/service/password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  response;
  public sent: boolean = false;
  loading = false;
  
  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private passwordService: PasswordService,
  ) {

    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    this.loading = true;
    const formData = new FormData();
    formData.append('email', this.forgotPasswordForm.get('email').value);
    this.passwordService.sendToken(formData).subscribe(
      (res) => {
        // this.response = res;
        // console.log(res);
        this.loading = false;
        this.sent = true;
      },
      (err) => {  
        console.clear()
        setTimeout(() => {
          this.loading = false;
          this.sent = true;
        }, 5000);
        // console.log(err);
      }
    );
  }
}
