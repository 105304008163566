import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient, private cookie: CookieService) { }

  public upload(formData) {
    let token = this.cookie.get('token');
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `${token}`,
      })
    };
    return this.http.put<any>(`${environment.apiEndpoint}/upload/`, formData, httpOptions);
  }

}
