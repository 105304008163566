import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/service/authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { PasswordService } from "src/app/service/password.service";
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { CustomValidators } from '../../validators/custom-validators';

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"]
})
export class PasswordResetComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  public sent: boolean = false;
  loading = false;
  token;
  unhide: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private passwordService: PasswordService,
    private spinner: NgxSpinnerService
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
    }

    this.forgotPasswordForm = this.fb.group({
      password: [
        "",
        [
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            // CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
            // 4. check whether the entered password has a lower-case letter
            // CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            // CustomValidators.patternValidator(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[^\sa-zA-Z0-9]).{8,}/, { hasSpecialCharacters: true }),
            Validators.minLength(8)
          ]
      ]
    });
  }

  ngOnInit() {
    this.spinner.show();
    this.token = this.route.snapshot.queryParamMap.get("token");
    this.verifyToken();
  }

  onSubmit() {
    this.loading = true;
    const formData = new FormData();
    formData.append("password", this.forgotPasswordForm.get("password").value);
    formData.append("token", this.token);
    this.passwordService.passwordReset(formData).subscribe(
      res => {
        // this.response = res;
        // console.log(res);
        if (res["status"] == "400") {
          // set error message from server side validation
          const formControl = this.forgotPasswordForm.get("password");
          if (formControl) {
            this.loading = false;
            formControl.setErrors({
              serverError: res["errors"]
            });
          }
          this.loading = false;
          this.sent = false;
        } else {
          this.loading = false;
          this.sent = true;
        }
      },
      err => {
        console.log(err);
        this.loading = false;
        // console.log(err);
      }
    );
  }

  // verify password reset token
  verifyToken() {
    if (this.token) {
      this.passwordService.validateToken(this.token).subscribe(
        response => {
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.router.navigate(["/"]);
        }
      );
    } else {
      this.router.navigate(["/"]);
    }
  }
}
