import { MatDialog } from "@angular/material";
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  termsAndConditions(){
    const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
      width: '500px',
      data: {}
    });
  }

}
