import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
   }

  sendToken(formData): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/api/password_reset/`, formData);
  }

  passwordReset(formData): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/api/password_reset/confirm/`, formData);
  }

  validateToken(token): Observable<any>{
    return this.http.get(`${environment.apiEndpoint}/api/password/validate_token/${token}`);
  }

}
