import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Member } from '../model/member';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from './alert.service';
import { ToastrService } from 'ngx-toastr';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Member>;
  public currentUser: Observable<Member>;
  private httpOptions: any;

  constructor(private http: HttpClient, private cookieService: CookieService,
    private toastr: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<Member>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

  }

  public get currentUserValue(): Member {
    return this.currentUserSubject.value;
  }

  private _token: string = null;

  set token(token: string) {
    this._token = token;
  }

  get token() {
    return this._token;
  }

  isLoggedIn() {
    return this.token != null;
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiEndpoint}/api/token/`, { "email": email, "password": password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        // store jwt in a cookie
        this.cookieService.set('token', `Bearer ${user.access}`, null, "/", null, false, 'Strict');

        this.token = this.cookieService.get('token');

        // reinitialize the currentUservalue
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.cookieService.deleteAll("/");
    this.currentUserSubject.next(null);
    this.toastr.success("You have logged out",'',
    {
      positionClass: 'toast-top-center',
      closeButton: true
    });
  }

  verifyReferralCode(code){
    return this.http.get(
      environment.apiEndpoint + "/api/verify/referral/code/" + code + "/",
      this.httpOptions
    );
  }

  resendEmail(){
    // let token = this.cookie.get('emailToken');
    let token = localStorage.getItem('emailToken');
    return this.http.get(
      environment.apiEndpoint + "/api/resend/email/token/" + token + "/",
      this.httpOptions
    );
  }

}
